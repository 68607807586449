export const testListApi = '/list'
export const testStoreApi = '/store'
export const testUpdateApi = '/update'
export const testToggleStatusApi = '/toggle-status'

// warehouse complain api
export const complainListApi = '/master-warehouse-complain/list'
export const complainStoreApi = '/master-warehouse-complain/store'
export const complainUpdateApi = '/master-warehouse-complain/update'
export const complainToggleStatusApi = '/master-warehouse-complain/toggle-status'
export const complainShowApi = '/master-warehouse-complain/show'

// warehouse complain api
export const kpiReportApi = '/warehouse-kpi/report'

// warehouse rating entry
export const ratingEntry = '/service-performace-farmer-entry/store'
export const ratingList = '/service-performace-farmer-entry/list'

//  service monitoring list
export const serviceMonitoringList = '/service-monitoring/report'

export const reportHeadingList = '/master-warehouse-report-heading/detail'
export const spaceAvailabilityDashboardApi = '/warehouse-service/space-availability-dashboard'
export const documentCategoryupdate = ''
export const documentCategorystore = ''
